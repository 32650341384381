const apiUrl = 'https://d2sbi3ky62l7nn.cloudfront.net';

export default {
	api: {
		posts: `${apiUrl}/posts`,
	},
	defaultAuthorImage:
		'https://cdn.homekeepr.com/hubfs/2024%20homekeeper%20logo_mark%20only%20green.svg',
	perPage: {
		listing: 12,
		recent: 3,
	},
	siteName: 'homekeepr',
	url: 'https://blog.homekeepr.com',
	version: '20250225',
};
