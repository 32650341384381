import HttpClient from '@mooveguru/js-http-client';
import config from '../config/local/blog';
import { hubspotPaginationPageToAfter } from 'utils/hubspot';

/** @typedef {import ('../Blog/PostExcerpt').default} PostExcerpt */
/** @typedef {import ('../Blog/PostExcerptData').default} PostExcerptData */

// @ts-ignore issue with httpClient package
const httpClient = new HttpClient();

/**
 * @param {string} slug
 */
export async function getPost(slug) {
	const response = await httpClient.get(`${config.api.posts}/${slug}`);

	if (!response.isOk) {
		throw new Error();
	}

	const data = response.body.results?.[0];

	if (data === undefined) {
		return null;
	}

	return {
		author: {
			headshotUrl: config.defaultAuthorImage, // not provided by API,
			name: data.authorName,
		},
		canonical: `${config.url}/${slug}`,
		content: data.postBody,
		date: new Date(data.publishDate),
		excerpt: data.postSummary,
		imageUrl: data.featuredImage,
		title: data.name,
		type: 'post',
	};
}

/**
 * @param {string} searchQuery
 * @param {number} page
 * @returns {Promise<{posts: PostExcerpt[], totalPages: number}>}
 */
// eslint-disable-next-line complexity
export async function getPostExcerpts(searchQuery, page) {
	const searchParamsBuilder = new URLSearchParams();
	searchParamsBuilder.append('_v', config.version);

	if (searchQuery) {
		searchParamsBuilder.append('name__icontains', searchQuery);
	}

	if (page && page > 1) {
		searchParamsBuilder.append(
			'after',
			hubspotPaginationPageToAfter(page - 1, config.perPage.listing)
		);
	}

	const searchParams = searchParamsBuilder.toString();
	const url =
		searchParams === ''
			? config.api.posts
			: `${config.api.posts}?${searchParams}`;
	const response = await httpClient.get(url);

	if (!response.isOk) {
		throw new Error();
	}

	let totalPages = 0;
	if (response.body.total > 0) {
		totalPages = Math.ceil(response.body.total / config.perPage.listing);
	}

	return {
		posts: response.body.results?.map(
			(
				/** @type {{ authorName: string; postSummary: string; publishDate: string; featuredImage: string; slug: string; name: string; }} */ data
			) => ({
				author: {
					headshotUrl: config.defaultAuthorImage, // not provided by API
					name: data.authorName,
				},
				content: data.postSummary,
				date: new Date(data.publishDate),
				imageUrl: data.featuredImage,
				slug: data.slug.split('/').pop(),
				title: data.name,
			})
		),
		totalPages,
	};
}

/**
 *
 */
export async function getLatestPostExcerpt() {
	const searchParamsBuilder = new URLSearchParams();
	searchParamsBuilder.append('_v', config.version);
	searchParamsBuilder.append('limit', '1');
	const searchParams = searchParamsBuilder.toString();

	const url =
		searchParams === ''
			? config.api.posts
			: `${config.api.posts}?${searchParams}`;
	const response = await httpClient.get(url);

	if (!response.isOk) {
		throw new Error();
	}

	const data = response.body?.results?.[0];

	if (data === undefined) {
		return null;
	}

	return {
		author: {
			headshotUrl: config.defaultAuthorImage, // not provided by API,
			name: data.authorName,
		},
		content: data.postSummary,
		date: new Date(data.publishDate),
		imageUrl: data.featuredImage,
		slug: data.slug.split('/').pop(),
		title: data.name,
	};
}
