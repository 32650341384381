import config from '../../config/local/blog';
import { hubspotPaginationPageToAfter } from 'utils/hubspot';
import images from '../../config/local/images';

/** @typedef {import('shared/BlogService/BlogPost').default} BlogPost */
/** @typedef {import('shared/BlogService/BlogPostExcerpt').default} BlogPostExcerpt */
/** @typedef {import('shared/BlogService/GetsBlogPost').default} GetsBlogPost */
/** @typedef {import('shared/BlogService/GetsBlogPostExcerpts').default} GetsBlogPostExcerpts */
/** @typedef {import('shared/BlogService/GetsLatestBlogPostExcerpt').default} GetsLatestBlogPostExcerpt */
/** @typedef {import('@mooveguru/js-http-client/GetsHttp').default} GetsHttp */

/**
 * @implements {GetsBlogPostExcerpts}
 * @implements {GetsBlogPost}
 * @implements {GetsLatestBlogPostExcerpt}
 */
export default class BlogService {
	/** @param {GetsHttp} httpClient */
	constructor(httpClient) {
		this.httpClient = httpClient;
	}

	/** @returns {Promise<?BlogPostExcerpt>} */
	async getLatestPostExcerpt() {
		const searchParamsBuilder = new URLSearchParams();
		searchParamsBuilder.append('_v', config.version);
		searchParamsBuilder.append('limit', '1');
		const searchParams = searchParamsBuilder.toString();

		const url =
			searchParams === ''
				? config.api.posts
				: `${config.api.posts}?${searchParams}`;
		const response = await this.httpClient.get(url);

		if (!response.isOk) {
			throw new Error(
				'Something went wrong getting your latest blog post excerpt.'
			);
		}

		const data = response.body?.results?.[0];

		if (data === undefined) {
			return null;
		}

		return {
			author: {
				headshotUrl: config.defaultAuthorImage, // not provided by API,
				name: data.authorName,
			},
			content: data.postSummary,
			date: new Date(data.publishDate),
			imageUrl: data.featuredImage || images.blog.default.medium,
			slug: data.slug.split('/').pop(),
			title: data.name,
		};
	}

	/**
	 * @param {string} slug
	 * @returns {Promise<?BlogPost>}
	 */
	async getPost(slug) {
		const response = await this.httpClient.get(
			`${config.api.posts}/${slug}`
		);

		if (!response.isOk) {
			throw new Error('Something went wrong getting your blog post.');
		}

		const data = response.body.results?.[0];

		if (data === undefined) {
			return null;
		}

		return {
			author: {
				headshotUrl: config.defaultAuthorImage, // not provided by API,
				name: data.authorName,
			},
			content: data.postBody,
			date: new Date(data.publishDate),
			imageUrl: data.featuredImage || images.blog.default.medium,
			title: data.name,
		};
	}

	/**
	 * @param {string} searchQuery
	 * @param {number} page
	 * @param {number} [perPage]
	 * @returns {Promise<{posts: BlogPostExcerpt[], totalPages: number}>}
	 */
	// this rule is for the birds...
	// eslint-disable-next-line complexity
	async getPostExcerpts(searchQuery, page, perPage = config.perPage.listing) {
		const searchParamsBuilder = new URLSearchParams();
		searchParamsBuilder.append('_v', config.version);
		searchParamsBuilder.append('limit', perPage.toFixed(0));

		if (searchQuery) {
			searchParamsBuilder.append('name__icontains', searchQuery);
		}

		if (page && page > 1) {
			searchParamsBuilder.append(
				'after',
				hubspotPaginationPageToAfter(page - 1, perPage)
			);
		}

		const searchParams = searchParamsBuilder.toString();
		const url =
			searchParams === ''
				? config.api.posts
				: `${config.api.posts}?${searchParams}`;
		const response = await this.httpClient.get(url);

		if (!response.isOk) {
			throw new Error(
				'Something went wrong getting your blog post excerpts.'
			);
		}

		let totalPages = 0;
		if (response.body.total > 0) {
			totalPages = Math.ceil(response.body.total / perPage);
		}

		return {
			posts: response.body.results?.map(
				(
					/** @type {{ authorName: string; postSummary: string; publishDate: string; featuredImage: string; slug: string; name: string; }} */ data
				) => ({
					author: {
						headshotUrl: config.defaultAuthorImage, // not provided by API
						name: data.authorName,
					},
					content: data.postSummary,
					date: new Date(data.publishDate),
					imageUrl: data.featuredImage || images.blog.default.medium,
					slug: data.slug.split('/').pop(),
					title: data.name,
				})
			),
			totalPages,
		};
	}
}
