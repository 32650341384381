/**
 * @param {string} after
 * @param {number} limit
 * @returns {number}
 */
export function hubspotPaginationAfterToPage(after, limit) {
	const decodedToken = decodeURIComponent(after);
	const decodedValue = atob(decodedToken);
	const offset = parseInt(decodedValue);
	return offset / limit;
}

/**
 * @param {number} page
 * @param {number} limit
 * @returns {string}
 */
export function hubspotPaginationPageToAfter(page, limit) {
	const offset = page * limit;
	const encodedValue = btoa(offset.toString());
	return encodeURIComponent(encodedValue);
}
